@mixin fade($num:1, $fade:1, $visible:2) {
  $a: 100 / (($fade + $visible) * $num);
  @keyframes fade {
    0% {
      opacity: 0;
    }

    #{$a * $fade}% {
      opacity: 1;
    }

    #{$a * ($fade + $visible)}% {
      opacity: 1;
    }

    #{$a * ($fade + $visible + $fade)}% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }
  animation-name: fade;
  animation-duration: (($fade + $visible) * $num) + s;
  animation-iteration-count: infinite;
  @for $i from 1 through $num {
    &:nth-child(#{$i}) {
      animation-delay: (#{($fade + $visible) * ($i - 1)}) + s;
    }
  }
}
// .fadein > div {
//   position: absolute;
//   left: 0;
//   right: 0;
//   opacity: 0;
//   width: 100%;
//   height: 100vh;
//   @include fade($num:3, $fade:3, $visible:2);
// }

body,
html {
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
}

html {
  font-size: calc(14px + (36 - 14) * ((100vw - 300px) / (1600 - 300)));
}

h1 {
  font-family: 'Trocchi', serif;
  color: white;
  text-shadow: 0.5rem 0.5rem 0.75rem black;
  font-size: 300%;
  position: absolute;
  bottom: 3%;
  right: 5%;
}

.abstract-1 {
  background-image: url("../../assets/images/abstract-1.jpg");
}

.abstract-2 {
  background-image: url("../../assets/images/abstract-2.jpg");
}

.abstract-3 {
  background-image: url("../../assets/images/abstract-3.jpg");
}

.abstract-4 {
  background-image: url("../../assets/images/abstract-4.jpg");
}

.abstract-5 {
  background-image: url("../../assets/images/abstract-5.jpg");
}

.abstract-6 {
  background-image: url("../../assets/images/abstract-6.jpg");
}

.abstract-7 {
  background-image: url("../../assets/images/abstract-7.jpg");
}

.abstract-8 {
  background-image: url("../../assets/images/abstract-8.jpg");
}

.abstract-9 {
  background-image: url("../../assets/images/abstract-9.jpg");
}

.abstract-10 {
  background-image: url("../../assets/images/abstract-10.jpg");
}

.abstract-11 {
  background-image: url("../../assets/images/abstract-11.jpg");
}

.abstract-12 {
  background-image: url("../../assets/images/abstract-12.jpg");
}

.abstract-13 {
  background-image: url("../../assets/images/abstract-13.jpg");
}